import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../component/layout"
import Seo from "../component/seo"



const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  
  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage title="Blog">
      <Seo title="All posts" />
      {/* <Bio /> */}
      <div className="container my-3">
        <ol className="post-list">
          {posts.map(post => {
            const title = post.title
            const blogpost =post.excerpt
            const featuredImage = {
              images: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
              alt: post.featuredImage?.node?.alt || ``,
            } 
            return (
              <li key={post.uri} className="mb-3 post-list-item">
                <article
                  className=""
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <div className="row">
                    {featuredImage?.images && (
                      <div className="col-auto col-xs-12 mb-2 mb-sm-0">
                          <GatsbyImage
                            image={featuredImage.images}
                            alt={featuredImage.alt}
                            // style={{ marginBottom: 50 }}
                          />
                      </div>
                    )}
                    <div className="col-sm col-xs-12">
                      <header>
                        <h4>
                          <Link to={post.uri} itemProp="url" className="text-dark">
                            <span itemProp="headline">{parse(title)}</span>
                          </Link>
                        </h4>
                        <small>
                          {post.date} 
                          {/* / By {post.author.node.name} */}
                        </small>
                      </header>
                      <section itemProp="description">
                        {parse(post.excerpt)}
                        {(blogpost.length >= 300) &&
                          <p>
                            <Link className="btn btn-blue btn-sm" to={post.uri}> Read More »</Link>
                          </p>
                        }
                      </section>
                    </div>
                  </div>
                </article>
              </li>
            )
          })}
        </ol>
        <div className="blog-post-nav d-flex flex-wrap justify-content-between">
          {previousPagePath && (
              <Link to={previousPagePath} className="btn btn-blue btn-sm my-2">Previous page</Link>
          )}
          {nextPagePath && <Link to={nextPagePath} className="btn btn-blue btn-sm my-2">Next page</Link>}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int, $postsPerPage: Int) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Uncategorized"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: DOMINANT_COLOR
                  layout: CONSTRAINED
                  width: 150
                )
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
      }
    }
  }
`

